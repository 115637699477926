import { ConnectedRouter } from 'connected-react-router';
import decode from 'jwt-decode';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Progress } from 'reactstrap';
import './App.scss';
import { LoginCallback } from './components/LoginCallback';
import { LoginErrorsCallback } from './components/LoginErrorsCallback';
import { ProgressBar } from './components/ProgressBar';
import { Trober } from './components/Trober';
import ErrorBoundary from './containers/ErrorLayout/ErrorBoundary';
import initStore, { history } from './store';
import { RelationTableModal } from './components/Modal/RelationTableModal';
import { RdevService } from './services/RdevService';


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const WidgetLayout = React.lazy(() => import('./containers/WidgetLayout'));
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));
const ProfileLayout = React.lazy(() => import('./containers/ProfileLayount'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
//const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const DefaultLogoutUrl = "/login";

const BannedUserStatus = 403;

/** Компонент для работы с авторизацией пользователя */
const auth = {
	/** Проверка валидности токена, по его времени жизни */
	isAuthenticated: () => {
		const token = localStorage.getItem('token');
		try {
			const { exp } = decode(token);
			if (exp < Date.now().valueOf() / 1000) {
				localStorage.removeItem('token');
			}
		} catch (err) {
			console.error("Authentication error:", err);
			return false;
		}

		return true;
	},

	/**
	 * Проверка блокировки пользователя по статусу, и де авторизация в случае блока
	 * @param {any} status числовой номер статуса ответа
	 */
	checkBanned: (status) => {
		if (status == BannedUserStatus) {
			auth.signOut();
		}
	},

	/**
	 * Деавторизация по клику на кнопку с вызовом некого действия
	 * @param {any} e
	 * @param {any} logoutUrl
	 */
	signOutEvent: (e, logoutUrl) => {
		e.preventDefault();
		auth.signOut(logoutUrl);
	},

	/**
	 * Деавторизация пользователя
	 * @param {any} logoutUrl
	 */
	signOut: (logoutUrl) => {
		const url = !logoutUrl ? DefaultLogoutUrl : logoutUrl;
		// Перенес очистку авторизационных данных в сервис
		RdevService.clearAuthData()
			.then( () => {
				window.location.assign(url);
			});
	},

	/**
	 * Получение адреса, для перенаправления после деавторизации
	 * @param {any} settings ответ запроса к /settings/getsettings
	 */
	getLogoutUrl: (settings) => {
		if (settings.data != null && settings.data.logoutUrl != null)
			return settings.data.logoutUrl;
		else
			return DefaultLogoutUrl;
	}
};

/** Компоненты ожидания загрузки */
const loading = {
	/** Большой троббер */
	bigRender: () => (<ProgressBar />),
	/** Малый троббер */
	smallRender: () => {
		return (
			<div className="animated fadeIn pt-1 text-center">
				<Progress animated value="100" className="rdev-progress-color">Загрузка...</Progress>
			</div>
		)
	}
};

// Private Route
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
	return (<Route {...rest} render={PrivateRender(Component)} />)
}

const PrivateRender = (Component) => (props) => {
	if (auth.isAuthenticated())
		return (<Component {...Object.assign({}, props, { auth: auth }, { loading: loading })} />)
	else
		return (<Redirect to={{ pathname: DefaultLogoutUrl, state: { from: props.location } }} />)
}

class App extends Component {
	render() {
		return (
			<Provider store={initStore}>

				<ConnectedRouter history={history}>
					<BrowserRouter>
						<Trober />
						<RelationTableModal />
						<React.Suspense fallback={loading.bigRender()}>
							<ErrorBoundary>
								<Switch>
									<Route exact path={DefaultLogoutUrl} name="Login Page" render={props => <Login {...props} />} />
									<Route exact path="/auth/login-callback/:token/:username" name="Login Page" render={props => <LoginCallback {...props} />} />
									<Route exact path="/auth/login-errors-callback/:provider/:message" name="Login Errors Page" render={props => <LoginErrorsCallback {...props} />} />
									<Route exact path="/register" name="Регистрация" render={props => <Register {...props} />} />
									{/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />*/}
									<PrivateRoute path="/admin" name="Администрирование" component={AdminLayout} />
									<PrivateRoute path="/account" name="Личный кабинет" component={ProfileLayout} />
									<PrivateRoute path="/widget" name="Виджеты" component={WidgetLayout} />
									<PrivateRoute path="/" name="Главная" component={DefaultLayout} />
								</Switch>
							</ErrorBoundary>
						</React.Suspense>
					</BrowserRouter>
				</ConnectedRouter>
			</Provider>
		)
	}
}

export default App;
